@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);
/* purgecss start ignore */

@keyframes splide-loading{
  0%{
    transform:rotate(0)
  }

  to{
    transform:rotate(1turn)
  }
}

.splide__track--draggable{
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  user-select:none
}

.splide__track--fade>.splide__list>.splide__slide{
  margin:0!important;
  opacity:0;
  z-index:0
}

.splide__track--fade>.splide__list>.splide__slide.is-active{
  opacity:1;
  z-index:1
}

.splide--rtl{
  direction:rtl
}

.splide__track--ttb>.splide__list{
  display:block
}

.splide__container{
  box-sizing:border-box;
  position:relative
}

.splide__list{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  display:flex;
  height:100%;
  margin:0!important;
  padding:0!important
}

.splide.is-initialized:not(.is-active) .splide__list{
  display:block
}

.splide__pagination{
  align-items:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  margin:0;
  pointer-events:none
}

.splide__pagination li{
  display:inline-block;
  line-height:1;
  list-style-type:none;
  margin:0;
  pointer-events:auto
}

.splide:not(.is-overflow) .splide__pagination{
  display:none
}

.splide__progress__bar{
  width:0
}

.splide{
  position:relative;
  visibility:hidden
}

.splide.is-initialized,.splide.is-rendered{
  visibility:visible
}

.splide__slide{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  box-sizing:border-box;
  flex-shrink:0;
  list-style-type:none!important;
  margin:0;
  position:relative
}

.splide__slide img{
  vertical-align:bottom
}

.splide__spinner{
  animation:splide-loading 1s linear infinite;
  border:2px solid #999;
  border-left-color:transparent;
  border-radius:50%;
  bottom:0;
  contain:strict;
  display:inline-block;
  height:20px;
  left:0;
  margin:auto;
  position:absolute;
  right:0;
  top:0;
  width:20px
}

.splide__sr{
  clip:rect(0 0 0 0);
  border:0;
  height:1px;
  margin:-1px;
  overflow:hidden;
  padding:0;
  position:absolute;
  width:1px
}

.splide__toggle.is-active .splide__toggle__play,.splide__toggle__pause{
  display:none
}

.splide__toggle.is-active .splide__toggle__pause{
  display:inline
}

.splide__track{
  overflow:hidden;
  position:relative;
  z-index:0
}

/* purgecss end ignore */
/* purgecss start ignore */

.splide__container{
  box-sizing:border-box;
  position:relative
}

.splide__list{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  display:flex;
  height:100%;
  margin:0!important;
  padding:0!important
}

.splide.is-initialized:not(.is-active) .splide__list{
  display:block
}

.splide__pagination{
  align-items:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  margin:0;
  pointer-events:none
}

.splide__pagination li{
  display:inline-block;
  line-height:1;
  list-style-type:none;
  margin:0;
  pointer-events:auto
}

.splide:not(.is-overflow) .splide__pagination{
  display:none
}

.splide__progress__bar{
  width:0
}

.splide{
  position:relative;
  visibility:hidden
}

.splide.is-initialized,.splide.is-rendered{
  visibility:visible
}

.splide__slide{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  box-sizing:border-box;
  flex-shrink:0;
  list-style-type:none!important;
  margin:0;
  position:relative
}

.splide__slide img{
  vertical-align:bottom
}

.splide__spinner{
  animation:splide-loading 1s linear infinite;
  border:2px solid #999;
  border-left-color:transparent;
  border-radius:50%;
  bottom:0;
  contain:strict;
  display:inline-block;
  height:20px;
  left:0;
  margin:auto;
  position:absolute;
  right:0;
  top:0;
  width:20px
}

.splide__sr{
  clip:rect(0 0 0 0);
  border:0;
  height:1px;
  margin:-1px;
  overflow:hidden;
  padding:0;
  position:absolute;
  width:1px
}

.splide__toggle.is-active .splide__toggle__play,.splide__toggle__pause{
  display:none
}

.splide__toggle.is-active .splide__toggle__pause{
  display:inline
}

.splide__track{
  overflow:hidden;
  position:relative;
  z-index:0
}

@keyframes splide-loading{
  0%{
    transform:rotate(0)
  }

  to{
    transform:rotate(1turn)
  }
}

.splide__track--draggable{
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  user-select:none
}

.splide__track--fade>.splide__list>.splide__slide{
  margin:0!important;
  opacity:0;
  z-index:0
}

.splide__track--fade>.splide__list>.splide__slide.is-active{
  opacity:1;
  z-index:1
}

.splide--rtl{
  direction:rtl
}

.splide__track--ttb>.splide__list{
  display:block
}

.splide__arrow{
  align-items:center;
  background:#ccc;
  border:0;
  border-radius:50%;
  cursor:pointer;
  display:flex;
  height:2em;
  justify-content:center;
  opacity:.7;
  padding:0;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  width:2em;
  z-index:1
}

.splide__arrow svg{
  fill:#000;
  height:1.2em;
  width:1.2em
}

.splide__arrow:hover:not(:disabled){
  opacity:.9
}

.splide__arrow:disabled{
  opacity:.3
}

.splide__arrow:focus-visible{
  outline:3px solid #0bf;
  outline-offset:3px
}

.splide__arrow--prev{
  left:1em
}

.splide__arrow--prev svg{
  transform:scaleX(-1)
}

.splide__arrow--next{
  right:1em
}

.splide.is-focus-in .splide__arrow:focus{
  outline:3px solid #0bf;
  outline-offset:3px
}

.splide__pagination{
  bottom:.5em;
  left:0;
  padding:0 1em;
  position:absolute;
  right:0;
  z-index:1
}

.splide__pagination__page{
  background:#ccc;
  border:0;
  border-radius:50%;
  display:inline-block;
  height:8px;
  margin:3px;
  opacity:.7;
  padding:0;
  position:relative;
  transition:transform .2s linear;
  width:8px
}

.splide__pagination__page.is-active{
  background:#fff;
  transform:scale(1.4);
  z-index:1
}

.splide__pagination__page:hover{
  cursor:pointer;
  opacity:.9
}

.splide__pagination__page:focus-visible{
  outline:3px solid #0bf;
  outline-offset:3px
}

.splide.is-focus-in .splide__pagination__page:focus{
  outline:3px solid #0bf;
  outline-offset:3px
}

.splide__progress__bar{
  background:#ccc;
  height:3px
}

.splide__slide{
  -webkit-tap-highlight-color:rgba(0,0,0,0)
}

.splide__slide:focus{
  outline:0
}

@supports(outline-offset:-3px){
  .splide__slide:focus-visible{
    outline:3px solid #0bf;
    outline-offset:-3px
  }
}

@media screen and (-ms-high-contrast:none){
  .splide__slide:focus-visible{
    border:3px solid #0bf
  }
}

@supports(outline-offset:-3px){
  .splide.is-focus-in .splide__slide:focus{
    outline:3px solid #0bf;
    outline-offset:-3px
  }
}

@media screen and (-ms-high-contrast:none){
  .splide.is-focus-in .splide__slide:focus{
    border:3px solid #0bf
  }

  .splide.is-focus-in .splide__track>.splide__list>.splide__slide:focus{
    border-color:#0bf
  }
}

.splide__toggle{
  cursor:pointer
}

.splide__toggle:focus-visible{
  outline:3px solid #0bf;
  outline-offset:3px
}

.splide.is-focus-in .splide__toggle:focus{
  outline:3px solid #0bf;
  outline-offset:3px
}

.splide__track--nav>.splide__list>.splide__slide{
  border:3px solid transparent;
  cursor:pointer
}

.splide__track--nav>.splide__list>.splide__slide.is-active{
  border:3px solid #000
}

.splide__arrows--rtl .splide__arrow--prev{
  left:auto;
  right:1em
}

.splide__arrows--rtl .splide__arrow--prev svg{
  transform:scaleX(1)
}

.splide__arrows--rtl .splide__arrow--next{
  left:1em;
  right:auto
}

.splide__arrows--rtl .splide__arrow--next svg{
  transform:scaleX(-1)
}

.splide__arrows--ttb .splide__arrow{
  left:50%;
  transform:translate(-50%)
}

.splide__arrows--ttb .splide__arrow--prev{
  top:1em
}

.splide__arrows--ttb .splide__arrow--prev svg{
  transform:rotate(-90deg)
}

.splide__arrows--ttb .splide__arrow--next{
  bottom:1em;
  top:auto
}

.splide__arrows--ttb .splide__arrow--next svg{
  transform:rotate(90deg)
}

.splide__pagination--ttb{
  bottom:0;
  display:flex;
  flex-direction:column;
  left:auto;
  padding:1em 0;
  right:.5em;
  top:0
}

/* purgecss end ignore */
/* purgecss start ignore */

.wallet-adapter-button {
  background-color: #404144;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
}

.wallet-adapter-button-trigger {
  background-color: #4e44ce;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: white;
}

.wallet-adapter-button:not([disabled]):hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.wallet-adapter-button[disabled] {
  background: #404144;
  color: #999;
  cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.wallet-adapter-button-end-icon {
  margin-left: 8px;
}

.wallet-adapter-button-start-icon {
  margin-right: 8px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  position: relative;
  display: inline-block;
}

.wallet-adapter-dropdown-list {
  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  top: 100%;
  right: 0;
  margin: 0;
  list-style: none;
  background: #2c2d30;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  background: #404144;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  height: 37px;
  color: white;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.wallet-adapter-modal-collapse-button svg {
  align-self: center;
  fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  transform: rotate(180deg);
  transition: transform ease-in 150ms;
}

.wallet-adapter-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity linear 150ms;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 6px;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: white;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-logo {
  max-width: 100%;
}

.wallet-adapter-modal-logo-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: #141515;
  margin-bottom: 10px;
  padding: 12px;
}

.wallet-adapter-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wallet-adapter-modal-container {
  display: flex;
  margin: 3rem;
  min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    margin: 1rem;
    min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1050;
  max-width: 400px;
  border-radius: 10px;
  background: #2c2d30;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  font-family: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding: 40px 20px 20px;
  flex: 1 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-wrapper-no-logo {
  padding-top: 30px;
}

.wallet-adapter-modal-title {
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  margin-bottom: 27px;
  margin-top: 0;
  width: 100%;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 26px;
  }
}

.wallet-adapter-modal-list {
  margin: 0 0 12px;
  padding: 0;
  width: 100%;
  list-style: none;
}

.wallet-adapter-modal-list li:not(:first-of-type) {
  margin-top: 12px;
}

/* purgecss end ignore */

